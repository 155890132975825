@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.solar-icon path {
  fill: currentColor;
}

html {
  font-family: 'Inter';
}
.right-align,
.right-align input {
  text-align: right !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.announcement-img img {
  display: flex;
  width: 100%;
}
.svg-16px svg {
  font-size: 16px;
}

.MuiOutlinedInput-adornedStart p {
  min-width: fit-content !important;
}
